import React, { Component } from 'react';
import { Tabs, Menu, Modal } from 'antd';
import './App.css';
import Logo from './img/logo.png'

import BOX1 from './img/box/box1.jpg'
import BOX2 from './img/box/box2.jpg'
import BOX3 from './img/box/box3.jpg'
import BOX4 from './img/box/box4.jpg'

import WATER1 from './img/water/water1.jpg'
import WATER2 from './img/water/water2.jpg'
import WATER3 from './img/water/water3.jpg'

import EYEWRAP1 from './img/eyewrap/eyewrap1.jpg'
import EYEWRAP2 from './img/eyewrap/eyewrap2.jpg'
import EYEWRAP3 from './img/eyewrap/eyewrap3.jpg'

import ContactlensesColorPdf from './img/contactlensesColor.pdf'
import ContactlensesPdf from './img/contactlenses.pdf'
import NursingliquidPdf from './img/nursingliquid.pdf'
import EyeLotionPdf from './img/eyelotion.pdf'
import ZgPdf from './img/zg.pdf'

import ContactUs from './img/contactUs.png'
import BaIcon from './img/ba_icon.png'
import IndustryInfo from './img/industryInfo.png'
import ProductCenter from './img/productCenter.png'
import TouMing from './img/touming.png'
import Color from './img/color.png'
import EyeLotion from './img/eyeLotion.png'
import Nursingliquid from './img/nursingliquid.png'
import Footer from './img/footer.png'
import ContactlensesColor1 from './img/contactlensesColor1.jpg'
import ContactlensesColor2 from './img/contactlensesColor2.jpg'
import Contactlenses1 from './img/contactlenses1.jpg'
import Contactlenses2 from './img/contactlenses2.jpg'
import EyeLotion1 from './img/eyeLotion1.jpg'
import EyeLotion2 from './img/eyeLotion2.jpg'
import Nursingliquid1 from './img/nursingliquid1.jpg'
import Nursingliquid2 from './img/nursingliquid2.jpg'
import Haitao1 from './img/haitao1.jpg'
import Haitao2 from './img/haitao2.jpg'
import Ylqx from './img/ylqx.png'
import Yyzz from './img/yyzz.png'
import Dazzshop1 from './img/dazzshop1.png'
import Dazzshop2 from './img/dazzshop2.png'
import Kelaibo1 from './img/kelaibo1.png'
import Kelaibo2 from './img/kelaibo2.png'
import Kelaibo3 from './img/kelaibo3.png'
import Kuboou1 from './img/kuboou1.png'
import Kuboou2 from './img/kuboou2.png'
import Boshilun1 from './img/boshilun1.png'
import Boshilun2 from './img/boshilun2.png'
import Alcon1 from './img/alcon1.png'
import Alcon2 from './img/alcon2.png'
import Alcon3 from './img/alcon3.png'
import Ziranmei1 from './img/ziranmei1.png'
import Ziranmei2 from './img/ziranmei2.png'
import Aierkang1 from './img/aierkang1.png'
import Aierkang2 from './img/aierkang2.png'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      menuKey: "2",
      page: 1,
      type: "contactlensesColor"
    }
  }

  componentDidMount() {
  }

  menuSelect = (value) => {
    console.log(value)
    let { type } = this.state
    if (value.keyPath.length > 1) {
      type = value.keyPath[0]
    }
    this.setState({
      type,
      menuKey: value.key
    }, () => {
      let anchorElement = document.getElementById((value.keyPath[1] || value.keyPath[0]));
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    })
  }


  tabChange1 = (value) => {
    this.setState({
      type: value
    })
  }
  onDocumentComplete(pages) {
    this.setState({ page: 1, pages: pages });
  }
  onChange(page) {
    this.setState({
      page: page,
    });
  }
  render() {
    return (
      <div className="App">
        <div className="center">
          <div className="topNav">
            <div style={{ width: 1440, margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ background: "white" }}>
                <img style={{ width: 200, height: 80 }} src={Logo} alt="" />
              </div>
              <Menu mode="horizontal" onSelect={this.menuSelect} selectedKeys={[this.state.menuKey]}>
                <Menu.SubMenu onTitleClick={this.menuSelect.bind(this, {
                  key: "product",
                  keyPath: ["product"]
                })} key="product" title="产品信息展示中心" >
                  {/* <Menu.Item key="box">
                    伴侣盒
                  </Menu.Item> */}
                  {/* <Menu.Item key="water">
                    洗眼液
                  </Menu.Item> */}
                  {/* <Menu.Item key="eyewrap">
                    蒸汽眼罩
                  </Menu.Item> */}
                  <Menu.Item key="contactlensesColor">
                    彩色隐形眼镜
                  </Menu.Item>
                  <Menu.Item key="contactlenses">
                    透明隐形眼镜
                  </Menu.Item>
                  <Menu.Item key="eyeLotion">
                    润眼液
                  </Menu.Item>
                  <Menu.Item key="nursingliquid">
                    护理液
                  </Menu.Item>
                  {/* <Menu.Item key="haitao">
                    海淘
                  </Menu.Item> */}
                </Menu.SubMenu>
                <Menu.Item key="news">
                  行业及产品资讯
                </Menu.Item>
                <Menu.Item key="contact">
                  联系我们
                </Menu.Item>
              </Menu>
            </div>
          </div>
          <div id="product" className="product">
            <div className="wrap">
              <div className="title" style={{ position: "relative", marginBottom: 72 }}>
                <img src={ProductCenter} alt="" style={{ width: 501, height: 103 }} />
              </div>
              <div style={{ width: 250, margin: "auto", textAlign: "center", position: "relative" }}>
                <div style={{ position: "absolute", right: "-69%", top: "20%" }}>
                  <Tabs activeKey={this.state.type} tabBarGutter={60} onChange={this.tabChange1}>
                    {/* <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>伴侣盒</span>} key="box"></Tabs.TabPane> */}
                    {/* <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>洗眼液</span>} key="water"></Tabs.TabPane> */}
                    {/* <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>蒸汽眼罩</span>} key="eyewrap"></Tabs.TabPane> */}
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>彩色隐形眼镜</span>} key="contactlensesColor"></Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>透明隐形眼镜</span>} key="contactlenses"></Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>润眼液</span>} key="eyeLotion"></Tabs.TabPane>
                    <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>护理液</span>} key="nursingliquid"></Tabs.TabPane>
                    {/* <Tabs.TabPane tab={<span style={{ fontSize: 20 }}>海淘</span>} key="haitao"></Tabs.TabPane> */}
                  </Tabs>
                </div>
              </div>
              <div style={{ width: "1250px", margin: "auto", height: 500, marginTop: 156 }}>
                <ul style={{ display: "flex", justifyContent: "center" }}>
                  {
                    // 海淘
                    this.state.type === "haitao" && (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={Haitao1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            Luna日抛彩色隐形眼镜10片装（海外版）
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={Haitao2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42, wordBreak: "break-all" }}>
                            Refrear moisture55%含水日抛隐形眼镜30片装（海外购）
                          </div>
                        </li>
                      </>
                    )
                  }
                  {
                    // 护理液
                    this.state.type === "nursingliquid" && (
                      // <a href={NursingliquidPdf} target='_blank' style={{ width: 284, height: 380, display: 'inline-block' }}>
                      //   <li style={{ width: 284, height: 380, boxSizing: "border-box", listStyleType: "none", border: "1px solid #EAEAEA", cursor: 'pointer' }} >
                      //     <img src={Nursingliquid} alt="" style={{ width: "100%", height: "100%" }} />
                      //   </li>
                      // </a>
                      <>
                        <li onClick={() => { this.setState({ visible: true, src1: Aierkang1, src2: Aierkang2 }) }} style={{ cursor: "pointer", width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={Nursingliquid1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            【一代经典款】爱尔康傲滴隐形眼镜多功能护理液355ml
                          </div>
                        </li>
                        <li onClick={() => { this.setState({ visible: true, src1: Ziranmei1, src2: Ziranmei2 }) }} style={{ cursor: "pointer", width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={Nursingliquid2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            自然美隐形眼镜护理液60ml
                          </div>
                        </li>
                      </>
                    )
                  }
                  {
                    // 彩色隐形眼镜
                    this.state.type === "contactlensesColor" && (
                      // <a href={ContactlensesColorPdf} target='_blank' style={{ width: 284, height: 380, display: 'inline-block' }}>
                      //   <li style={{ width: 284, height: 380, boxSizing: "border-box", listStyleType: "none", border: "1px solid #EAEAEA", cursor: 'pointer' }} >
                      //     <img src={Color} alt="" style={{ width: "100%", height: "100%" }} />
                      //   </li>
                      // </a>
                      <>
                        <li onClick={() => { this.setState({ visible: true, src1: Dazzshop1, src2: Dazzshop2 }) }} style={{ cursor: "pointer", width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={ContactlensesColor1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            DAZZSHOP半年抛PANTO系列彩色隐形眼镜1片装
                          </div>
                        </li>
                        <li onClick={() => { this.setState({ visible: true, src1: Kelaibo3, src2: Kelaibo2, src3: Kelaibo1 }) }} style={{ cursor: "pointer", width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={ContactlensesColor2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            科莱博小黑裙半年抛美瞳1片装
                          </div>
                        </li>
                      </>
                    )
                  }
                  {
                    // 润眼液
                    ["eyeLotion"].includes(this.state.type) && (
                      // <a href={EyeLotionPdf} target='_blank' style={{ width: 284, height: 380, display: 'inline-block' }}>
                      //   <li style={{ width: 284, height: 380, boxSizing: "border-box", listStyleType: "none", border: "1px solid #EAEAEA", cursor: 'pointer' }} >
                      //     <img src={EyeLotion} alt="" style={{ width: "100%", height: "100%" }} />
                      //   </li>
                      // </a>
                      <>
                        {/* <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EyeLotion1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            美汐隐形眼镜润滑液10ml
                          </div>
                        </li> */}
                        <li onClick={() => { this.setState({ visible: true, src1: Alcon1, src2: Alcon2, src3: Alcon3 }) }} style={{ cursor: "pointer", width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EyeLotion2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            Alcon爱尔康隐形眼镜保湿滴眼液15ml
                          </div>
                        </li>
                      </>
                    )
                  }
                  {
                    // 伴侣盒
                    this.state.type === "box" && (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            3N眼镜四代隐形眼镜清洗器
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            eyekan新款凯达HL-988隐形眼镜清洗器
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX3} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            凯达小黄鸭卡通隐形眼镜盒
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={BOX4} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            美尼康硬性隐形眼镜ITH双头镜盒伴侣盒
                          </div>
                        </li>
                      </>
                    )
                  }
                  {
                    this.state.type === "water" ? (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={WATER1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            LaPeche拉拜诗樱桃小丸子联名洗眼液
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={WATER2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            拉拜诗LaPeche维生素B12洗眼液
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={WATER3} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            日本科奈美维生素B12洗眼液
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                  {
                    // 蒸汽眼罩
                    this.state.type === "eyewrap" ? (
                      <>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EYEWRAP1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            LaPeche拉拜诗无香热敷蒸汽眼罩
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EYEWRAP2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            眸论5片装香味热敷蒸汽眼罩
                          </div>
                        </li>
                        <li style={{ width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={EYEWRAP3} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            眸论春日娃娃5片装香味热敷蒸汽眼罩
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                  {
                    // 透明隐形眼镜
                    this.state.type === 'contactlenses' ? (
                      <>
                        <li onClick={() => { this.setState({ visible: true, src1: Boshilun1, src2: Boshilun2 }) }} style={{ cursor: "pointer", width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA", marginRight: 12 }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={Contactlenses1} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            博士伦air薄巧半年抛隐形眼镜2片装
                          </div>
                        </li>
                        <li onClick={() => { this.setState({ visible: true, src1: Kuboou1, src2: Kuboou2 }) }} style={{ cursor: "pointer", width: 284, height: 380, boxSizing: "border-box", padding: 48, listStyleType: "none", border: "1px solid #EAEAEA" }}>
                          <div className="img" style={{ width: 188, height: 188 }}>
                            <img src={Contactlenses2} alt="" style={{ width: 188, height: 188 }} />
                          </div>
                          <div className="subtitle" style={{ fontSize: 20, color: "#0F1429", textAlign: "left", marginTop: 42 }}>
                            库博欧柯视日抛隐形眼镜30片装
                          </div>
                        </li>
                      </>
                    ) : null
                  }
                </ul>
              </div>
            </div>
          </div>
          <div id="news" style={{ marginBottom: "200px" }}>
            <div style={{ background: "#fff", textAlign: "center", }}>
              <img src={IndustryInfo} alt="" style={{ width: 510, height: 103 }} />
            </div>
            <div style={{ margin: "72px 190px 0" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <a target='_blank' href="/inyx?url=https://images.vsigo.cn/zhuanti/20220809/2.png">
                  <div style={{ width: 504, height: 375, background: "red", position: "relative" }}>
                    <img src="https://images.vsigo.cn/live/post/cover/400-/2021123113181347885_1000.jpg" style={{ height: "100%", width: "100%" }} alt="" />
                    <div style={{ boxShadow: "0px 0px 10px 0px rgba(187,187,187,0.3000)", position: "absolute", width: 434, height: 209, background: "#fff", top: "299px", left: "35px", boxSizing: "border-box", padding: "30px 48px 26px", borderRadius: 4 }}>
                      <p style={{ textAlign: "center", fontSize: 18, color: "#222222" }}>爱隐形</p>
                      <p style={{ textAlign: "center", fontSize: 20, color: "#222222", marginTop: 20, fontWeight: "bold" }}>近视有散光，隐形眼镜度数该如何选择？</p>
                      <p style={{ textAlign: "center", fontSize: 16, color: "#999999", marginTop: 16 }}>2022.08.01</p>
                    </div>
                  </div>
                </a>
                <a target='_blank' href="/inyx?url=https://images.vsigo.cn/zhuanti/20220809/1.png">
                  <div style={{ width: 504, height: 375, background: "red", position: "relative" }}>
                    <img src="https://images.vsigo.cn/live/post/cover/400-/2022610151622836642_800.jpg" style={{ height: "100%", width: "100%" }} alt="" />
                    <div style={{ boxShadow: "0px 0px 10px 0px rgba(187,187,187,0.3000)", position: "absolute", width: 434, height: 209, background: "#fff", top: "299px", left: "35px", boxSizing: "border-box", padding: "30px 48px 26px", borderRadius: 4 }}>
                      <p style={{ textAlign: "center", fontSize: 18, color: "#222222" }}>爱隐形</p>
                      <p style={{ textAlign: "center", fontSize: 20, color: "#222222", marginTop: 20, fontWeight: "bold" }}>新手快速入门——如何正确护理液隐形眼镜</p>
                      <p style={{ textAlign: "center", fontSize: 16, color: "#999999", marginTop: 16 }}>2022.08.01</p>
                    </div>
                  </div>
                </a>
                <a target="_blank" href="/inyx?url=https://images.vsigo.cn/zhuanti/20220809/3.png">
                  <div style={{ width: 504, height: 375, background: "red", position: "relative" }}>
                    <img src="https://images.vsigo.cn/live/post/cover/400-/20211231131853926322_1000.jpg" style={{ height: "100%", width: "100%" }} alt="" />
                    <div style={{ boxShadow: "0px 0px 10px 0px rgba(187,187,187,0.3000)", position: "absolute", width: 434, height: 209, background: "#fff", top: "299px", left: "35px", boxSizing: "border-box", padding: "30px 48px 26px", borderRadius: 4 }}>
                      <p style={{ textAlign: "center", fontSize: 18, color: "#222222" }}>爱隐形</p>
                      <p style={{ textAlign: "center", fontSize: 20, color: "#222222", marginTop: 20, fontWeight: "bold" }}>新手快速入门——如何正确摘取隐形眼镜</p>
                      <p style={{ textAlign: "center", fontSize: 16, color: "#999999", marginTop: 16 }}>2022.08.01</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div id="contact" style={{ marginBottom: 72 }}>
            <div style={{ background: "#fff", textAlign: "center", }}>
              <img src={ContactUs} alt="" style={{ width: 510, height: 103 }} />
            </div>
          </div>
          <div className="footer" style={{ textAlign: "center" }}>
            <img src={Footer} style={{ width: "100%", height: 278 }} alt="" />
          </div>
          <div style={{ background: "#222222", padding: "24px 0", textAlign: "center", color: "white" }}>
            <a style={{ color: "white", marginRight: 12 }} href={ZgPdf} target='_blank'>互联网药品信息服务资格证书编号：(苏)-非经营性-2021-0047</a>
            <a href="https://beian.miit.gov.cn/" style={{color: 'white'}} target="_blank">苏ICP备2020060826号</a>
            
            <div style={{display: 'inline-flex', alignItems: 'baseline', margin: '0 8px'}}>
              <img src={BaIcon} style={{width: '.72rem', height: '.80rem'}} />
              <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32010402001479" rel="noreferrer" target="_blank">苏公网安备32010402001479</a>
            </div>
            

            <a style={{ color: "white" }} href={Ylqx} target='_blank'>｜医疗器械经营许可证：苏宁食药监械经营许20200297号</a>
            <a style={{ color: "white" }} href={Yyzz} target='_blank'>｜经营者信息</a>
            <a style={{ color: "white" }} href={'https://s1.rgpmall.com/rgp/zhuanti/img/cert/dierleiyiliaobeian.png'} target='_blank'>｜第二类医疗器械经营备案凭证：苏宁备药监械经营备20221099号</a>
            {/* <a href={SM1} target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >医疗器械网络销售备案信息</a> */}
          </div>
          {/* <div id="us" className="us" style={{ position: "relative", }}>
            <div>
              <img src={FOOTER} alt="" />
              <a href={ZS1} className='zs_code' target='_blank' rel="noopener noreferrer" style={{ position: 'absolute', left: '56.6%', bottom: '10.1%', zIndex: 1, color: '#fff' }} >（沪）-非经营性-2021-0165</a>
              <div className='zs' style={{ position: "absolute", width: '100%', top: "92%", color: '#fff', fontSize: '16px', textAlign: 'center' }} >
                <a href="https://beian.miit.gov.cn/" target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >备案号：沪ICP备2021013322号-1</a>
                &nbsp;|&nbsp;
                <a href={ZS2} target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >医疗器械经营许可证：沪宝食药监械经营许20180053号</a>
                &nbsp;|&nbsp;
                <a href={ZS3} target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >医疗器械网络销售备案信息</a>
                &nbsp;|&nbsp;
                <a href={ZS4} target='_blank' rel="noopener noreferrer" style={{ color: '#fff' }} >经营者信息</a>
              </div>
            </div>
          </div> */}
        </div>
        <Modal destroyOnClose={true} footer={null} okText="确认" cancelText="取消" visible={this.state.visible} onCancel={() => { this.setState({ visible: false,src3:null }) }} width={1200}>
          <div style={{ height: 600, overflow: "scroll" }}>
            <img style={{ width: "100%" }} src={this.state.src1} alt="" />
            {
              this.state.src3 && <img style={{ width: "100%" }} src={this.state.src3} alt="" />
            }
            <img style={{ width: "100%" }} src={this.state.src2} alt="" />
          </div>
        </Modal>
        <style jsx="true">
          {
            `
            .App .ant-menu-horizontal {
              border-bottom: none !important;
            }
            `
          }
        </style>
      </div>
    );
  }
}

export default App;
