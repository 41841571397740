import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './index.css';
import App from './App';
import Inyx from './inyx'


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/inyx' component={Inyx}/>
      <App />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

