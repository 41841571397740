import React from 'react';
// import inyx from './lc-03.jpg'
class Inyx extends React.Component {
  state = {
    img:null
  }

  componentDidMount(){
    console.log(window.location)
    let img = window.location.search.split("url=")[1]
    console.log(img)
    this.setState({
      img:img,
    })
  }
  render() {
    return (
      <div style={{ textAlign: 'center', backgroundColor: '#f6f9fd' }}>
        <img src={this.state.img} style={{ width:'750px', backgroundColor:'#fff'}} alt='' />
      </div>
    )
  }
}
export default Inyx